import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    base: {
        position: 'relative',
    },
    weddingTop: {
        '&:before': {
            position: 'absolute',
            display: 'flex',
            content: '""',
            backgroundImage: 'url(https://cdn.newporthome.eu/media/ac/b1/6d/1724056666/Flower-Top-1.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            height: '70px',
            width: '140px',
            top: '16px',
            left: '50%',
            zIndex: '1',
            transformOrigin: 'top',
            transform: 'scale(1) translateY(-100%) translateX(-50%)',
        },
    },
    weddingBottom: {
        '&:after': {
            position: 'absolute',
            display: 'flex',
            content: '""',
            backgroundImage: 'url(https://cdn.newporthome.eu/media/ac/b1/6d/1724056666/Flower-Top-1.png)',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            height: '70px',
            width: '140px',
            bottom: '-49px',
            left: '50%',
            zIndex: '1',
            transform: 'scale(1) translateX(-50%) rotate(180deg)',
        },
    },
}, { name: 'ornament' });

/**
 *
 * @param {'wedding-bottom'|'wedding-top'|'christmas-bottom'} ornamentStyle
 * @param show
 * @param disableStyle
 * @param children
 * @param style
 * @returns {Element}
 * @constructor
 */
export default function Ornament(
    {
        show = true,
        ornamentStyle,
        disableStyle = false,
        children,
        style,
    },
) {
    const classes = useStyles();

    if (disableStyle) {
        return children;
    }

    if (!show) {
        return null;
    }

    let ornamentClass = null;

    if ('wedding-top' === ornamentStyle) {
        ornamentClass = classes.weddingTop;
    }

    if ('wedding-bottom' === ornamentStyle) {
        ornamentClass = classes.weddingBottom;
    }

    return <div style={style} className={[ornamentClass, classes.base].join(' ')}>
        {children}
    </div>
}
