import { useTranslation } from 'tools/i18n';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    stock: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontSize: '0.8em',
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: 1,
        marginBottom: 2,
        fontSmoothing: 'auto',
        '&:before': {
            content: '""',
            height: '5px',
            width: '5px',
            borderRadius: '50%',
        },
        '&.in-stock:before': {
            backgroundColor: 'green',
        },
        '&.black:before': {
            backgroundColor: 'black',
        },
        '&.out-of-stock:before': {
            backgroundColor: 'red',
        },
    },
}), { name: 'StockIndicator' });



/**
 *
 * @param {boolean} show
 * @param {number|null} stock
 * @param {object|null} deliveryTime
 * @param {object|null} style
 * @param {string|null} className
 * @returns {Element}
 * @constructor
 */
export default function StockIndicator(
    {
        show = true,
        stock,
        deliveryTime,
        style = null,
        className = null,
    },
) {
    const classes = useStyles();
    const classNames = `${classes.stock} ${className}`;
    const { t } = useTranslation();

    if (!show) {
        return null;
    }

    function resolveIndicator(deliveryTimeId) {
        if (!deliveryTimeId) {
            return null;
        }

        if (['5ae66ca4e2124856b52e872650f1de21'].includes(deliveryTimeId)) {
            return 'in-stock';
        }

        if (['45a286a87e28491b8c4802d0b395712d'].includes(deliveryTimeId)) {
            return 'out-of-stock';
        }

        return 'black';
    }

    if (deliveryTime) {
        let indicator = resolveIndicator(deliveryTime?.id);
        return <span style={style} className={`${classNames} ${indicator}`}>{deliveryTime?.translated?.name}</span>;
    }

    if (!stock) {
        return <span style={style} className={classNames}></span>;
    }

    return <>
        <span style={style} className={classNames}>
        </span>
    </>;
}
