import { useContext } from 'react';
import {makeStyles} from '@material-ui/styles';
import { SessionContext } from "../providers/session";
import theme from "../theme";
import WeddingIcon from "../../public/images/icons/wedding.svg";

const styles =  {
	wrapper: {
		display: 'flex',
	},
	trigger: {
		padding: '10px 7px',
		height: 40,
	},
	icon: {
		display: 'block',
		width: 20,
		height: 20,
		color: '#000000',
		strokeWidth: 0.5,
		fill: 'transparent'
	},
	badge: {
		verticalAlign: 'initial'
	},
	count: {
		color: theme.palette.primary.background,
		padding: '0 4px',
		fontSize: 11,
		backgroundColor: '#000',
		'.xmas &': {
			background: theme.palette.primary.background,
			color: '#000'
		}
	},
	centerModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: 10,
		minWidth: 250,
		backgroundColor: theme.palette.primary.background,
	},
	pleaseLogin: {
		maxWidth: 500,
		'& .title': {
			margin: '5px 0 5px',
			fontSize: 14,
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
			fontWeight: 600
		},
		'& .actions': {
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center'
		},
		'& .button': {
			padding: '8px 14px',
			alignItems: 'center',
			fontSize: 14,
			color: theme.palette.primary.background,
			textTransform: 'none',
			backgroundColor: theme.palette.primary.purchase.background,
			hover: theme.palette.primary.purchase.hover,
			borderRadius: 0
		}
	},
	wishlistModal: {
		position: 'fixed',
		top: '6%',
		left: '50%',
		width: '100%',
		padding: 15,
		transform: 'translate(-50%, 0%)',
		'&:focus': {
			outline: 'none'
		},
		'& $list': {
			position: 'relative',
			top: 'auto',
			right: 'auto'
		}
	},
	selectList: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 250,
		width: '100%',
		'& > p': {
			margin: '5px 0 5px',
			fontSize: 14,
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
			fontWeight: 600
		},
		'& $list': {
			position: 'relative',
			top: 'auto',
			right: 'auto',
			minWidth: 0,
			width: '100%',
			borderRadius: 0
		}
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		position: 'absolute',
		top: '85%',
		zIndex: 100,
		right: 40,
		minWidth: 280,
		'&.main': {
			padding: '10px 15px',
			backgroundColor: '#fafafa',
			'.xmas &': {
				color: '#000 !important'
			},
			'& $item': {
				backgroundColor: '#fafafa'
			}
		},
		'&.select': {
			top: 0,
			right: 0,
		}
	},
	notLoggedIn: {
		display: 'flex',
		'& span': {
			marginTop: 10,
		},
		'& a': {
			fontWeight: 500,
			color: 'inherit'
		}
	},
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		padding: '5px 8px',
		marginTop: 3,
		fontSize: 14,
		cursor: 'pointer',
		backgroundColor: '#f0f0f0',
		'&:hover': {
			backgroundColor: '#fafafa'
		},
		'& .name': {
			marginLeft: 6,
			marginRight: 'auto',
			lineHeight: '22px',
			color: 'inherit'
		},
		'& .count': {
			marginLeft: 10,
			fontWeight: 500
		},
		'& .title': {
			display: 'flex',
			'.xmas &': {
				color: '#000 !important'
			},
		},
		'&.active': {
			fontWeight: 600,
		},
		'&.new': {
			alignItems: 'center',
			width: 'auto',
			padding: '4px 6px',
			fontSize: 12,
			backgroundColor: theme.palette.primary.main + '!important',
			color: theme.palette.primary.background,
			cursor: 'pointer',

		},
		'.xmas &.new': {
			backgroundColor: theme.palette.primary.main + '!important',
			color: '#fff !important',
		},

		'&.empty': {
			color: '#555',
			fontSize: 12,
			backgroundColor: '#eaeaea !important'
		},
		'& a': {
			color: 'inherit'
		}
	},
	active: {
		'& .name': {
			fontWeight: 500,
		}
	},
	activeIcon: {
		marginRight: 5,
		fontSize: '1rem',
		cursor: 'pointer'
	},
	actionWrapper: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 8,
		marginTop: 10,
		'& > span ': {
			marginLeft: 'auto',
		},
		'& > span a': {
			color: 'inherit',
			fontSize: 12
		}
	},
	addToList: {
		marginLeft: 'auto',
		marginRight: -4,
		width: 36,
		minWidth: 0,
		padding: 5,
		borderRadius: 0,
		'& svg': {
			fontSize: 18,
			color: '#11284a'
		},
	},
	add: {
		marginRight: 8,
		fontSize: 16
	},
	createWishlist: {
		position: 'fixed',
		padding: '30px 20px 20px',
		maxWidth: 400,
		width: '100%',
		'&:focus': {
			outline: 'none'
		},
		'& p': {
			margin: '0 0 15px',
			fontSize: 13,
			fontWeight: 500,
			textTransform: 'uppercase',
			letterSpacing: 1
		}
	},
	createWishlistActions: {
		display: 'flex',
		gap: 10
	},
	createNameInput: {
		borderRadius: 0
	},
	createButton: {
		height: 40,
		color: `${theme.palette.primary.background} !important`,
		padding: '8px 16px',
		fontSize: 12,
		fontWeight: 600,
		letterSpacing: 2,
		backgroundColor: theme.palette.primary.main,
		borderRadius: 0,
		'&:hover': {
			backgroundColor: theme.palette.primary.main
		}
	},
	additional: {
		padding: 15,
		marginTop: 10,
		border: '1px solid #f0f0f0',
		'& $createNameInput': {
			marginBottom: 15
		}
	},
	isWishlistToggle: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 8
	},
	weddingToggle: {
		marginLeft: -12
	},
	weddingToggleChecked: {
		color: '#000 !important'
	},
	weddingLabel: {
		marginTop: 4,
		color: '#000',
		fontSize: 14,
		'& a': {
			color: 'inherit'
		}
	},
	buyButton: {
		...theme.button.purchase,
		backgroundColor: theme.palette.primary.purchase.background,
		padding: '14px 12px',
		minWidth: 0,
		flexGrow: 0,
		fontSize: 10,
		textTransform: 'uppercase',
		'&:hover': {
			...theme.button.purchaseHover
		},
		'&:before': {
			content: '""',
			height: 1,
			backgroundColor: theme.palette.primary.background,
			display: 'block',
			position: 'absolute',
			top: 4,
			left: 0,
			right: 0,
			transition: 'all 0.25s ease'
		},
		'&:after': {
			content: '""',
			height: 1,
			backgroundColor: theme.palette.primary.background,
			display: 'block',
			position: 'absolute',
			bottom: 4,
			left: 0,
			right: 0,
			transition: 'all 0.25s ease'
		},
	},
	weddingListAdd: {
		maxHeight: 58,
		marginTop: 16,
		marginBottom: 0,
		backgroundColor: '#a39161',
	},
}

const useStyles = makeStyles(() => styles);

export default function Wishlist() {
	const classes = useStyles();
	const { config } = useContext(SessionContext);

	return (
		<>
			<div className={[classes.wrapper, theme.mode].join(' ')}>
				{config.myNewport && config.language.locale === 'sv' &&
					<a href={`/inspiration/wishlist`} className={[classes.trigger, 'wedding'].join(' ')}>
						<WeddingIcon width={20} className={classes.icon}/>
					</a>
				}
			</div>
		</>
	)
}
