import { makeStyles } from '@material-ui/styles';
import theme from '../theme';
import CloseIcon from '../../public/images/icons/close.svg';
import { Drawer } from '@material-ui/core';

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        padding: '64px 20px 30px',
        flexDirection: 'column',
        width: 600,
        maxWidth: '90vw',
        [theme.breakpoints.down('sm')]: {
            padding: '64px 20px 30px',
        },
    },
    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.5)',
    },
    drawerClose: {
        position: 'absolute',
        top: 5,
        right: 3,
        zIndex: 10,
        marginLeft: 'auto',
        padding: 14,
        cursor: 'pointer',
        color: '#000 !important',
    },
    icon: {
        display: 'block',
        width: 20,
        height: 20,
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.primary.background,
        color: theme.palette.primary.color,
        '.blackfriday &': {
            border: '1px solid #fff',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: '100%',
        },
    },
}, { name: 'np-drawer' });

export default function NewportDrawer({ anchor = 'right', open, close, title, children }) {
    const classes = useStyles();

    return (
        <Drawer anchor={anchor} open={open} onClose={close} ModalProps={{ keepMounted: true, disablePortal: false }}>
            <aside className={classes.wrapper}>
                <CloseIcon className={classes.drawerClose} onClick={close} width={44} />
                {open ? children : null}
            </aside>
        </Drawer>
    );
}
