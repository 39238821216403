import { useRouter } from 'next/router';

export default function ProductLink(
    {
        product,
        className,
        children,
        style,
    },
) {
    const router = useRouter();
    const url = `/${product?.seoUrls?.[0].seoPathInfo}` || `/details/${product?.id}`;

    function handleClick(event) {
        event.preventDefault();
        router.push(url).then(r => null);
    }

    return <a
        href={url}
        style={style}
        className={className}
        onClick={handleClick}
    >
        {children}
    </a>;
}
