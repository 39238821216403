import { useTranslation } from 'tools/i18n';
import CallToActionButton from './CallToActionButton';
import { useContext, useState } from 'react';
import { SessionContext } from '../../providers/session';

/**
 * @param {Object} product
 * @param {CallToActionButton} props
 */
export default function PurchaseButton(
    {
        product,
        ...props
    },
) {
    const { t } = useTranslation();
    const session = useContext(SessionContext);
    const [ isLoading, setIsLoading ] = useState(false);

    function renderText(available, deliveryTime) {
        if (available) {
            return t('buy');
        }

        if (!deliveryTime?.translated?.name) {
            return t('out-of-stock');
        }

        return deliveryTime?.translated?.name;
    }

    async function handleAddToCart() {
        setIsLoading(true);
        try {
            await session.actions.addToCart({ product: product.id, quantity: 1 });
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    }

    return <CallToActionButton
        {...props}
        disabled={!product.available}
        onClick={handleAddToCart}
        isLoading={isLoading}
    >
        {renderText(product.available, product.deliveryTime)}
    </CallToActionButton>
}
