import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button } from "@material-ui/core";
import { FilterNone } from '@material-ui/icons';
import Link from "next/link";
import {makeStyles} from "@material-ui/styles";
import formatPrice from "functions/priceFormat";
import {TrackingContext} from "../providers/tracking";
import {useTranslation} from "tools/i18n";
import LazyHydrate from "tools/lazyHydrate";
import {SessionContext} from "../providers/session";
import ShoppingBagFill from "public/images/icons/shopping-bag-fill.svg";
import Envelope from "public/images/icons/meddela-mig-fill.svg";
import theme from "../theme";
import { elevateAddToCartNotification } from "tools/elevateClient";
import Cookies from 'js-cookie';
import ProductLabels from '../page/product/partial/labels';
import LikeButton from './wishlist/LikeButton';

const useStyles = makeStyles(() => ({
	item: {
		display: 'flex',
		'& > div': {
			width: '100%'
		},
		'.autoWidth &': {
			maxWidth: 'none',
			flexGrow: 1
		}
	},
	link: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		maxWidth: '100%',
		textDecoration: 'none',
		backgroundColor: theme.palette.primary.background,
		color: 'inherit'
	},
	imageWrapper: {
		position: 'relative',
		padding: '0',
		overflow: 'hidden',
		'&:after': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			pointerEvents: 'none',
			mixBlendMode: 'multiply',
			boxShadow: 'inset 0 0 90px 8px rgb(240, 240, 240, 0.75)'
		}
	},
	variantList: {
		display: 'none',
		padding: 0,
		margin: '12px 0 0',
		fontSize: 12,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		listStyle: 'none',
		[theme.breakpoints.down('sm')]: {
			marginTop: 6,
			fontSize: 10
		}
	},
	variant: {
		width: 'auto',
		marginRight: 4,
		padding: '2px 8px',
		backgroundColor: theme.palette.primary.background,
		border: '1px solid #ccc'
	},
	selectedVariant: {
		border: '1px solid #444'
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flexGrow: 1,
		overflow: 'hidden',
		padding: '14px 10px 20px 10px'
	},
	manufacturer: {
		fontFamily: 'Chronicle, "Open Sans", sans-serif',
		textTransform: 'uppercase',
		fontSize: 11,
		fontWeight: 400,
		letterSpacing: 0,
		textAlign: 'center',
		lineHeight: 1.5,
		marginBottom: 8,
		fontSmoothing: 'auto',
		[theme.breakpoints.down('sm')]: {
			fontSize: 10
		}
	},
	moreVariantLabels: {
		display: 'flex',
		alignItems: 'center',
		position: 'absolute',
		left: 12,
		bottom: 15,
		zIndex: 10,
		padding: '4px 6px',
		color: 'rgba(0,0,0,0.65)',
		fontSize: 12,
		fontWeight: 500,
		backgroundColor: 'transparent',
		'&.light': {
			color: '#fff',
			backgroundColor: 'transparent',
			filter: 'drop-shadow(0px 0px 1px #000)',
		},
		'&.in-cart': {
			opacity: 0,
		},
		[theme.breakpoints.down('sm')]: {
			bottom: 13,
			left: 5,
			fontSize: 10
		}
	},
	name: {
		margin: '0 0 8px',
    	maxWidth: '100%',
		fontSize: 15,
		fontWeight: 400,
		color: theme.palette.primary.lowlight,
		textAlign: 'center',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		fontSmoothing: 'auto',
		[theme.breakpoints.down('sm')]: {
			fontSize: 13,
			fontWeight: 400,
			marginBottom: 6
		}
	},
	prices: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center'
	},
	price: {
		fontSize: 15,
		fontWeight: 500,
		lineHeight: 1.5,
		fontSmoothing: 'auto',
		color: theme.palette.primary.lowlight,
		'&.special': {
			color: '#e65f5f'
		},
		'&.old': {
			marginLeft: 10,
			textDecoration: 'line-through',
			fontSize: 12,
			fontWeight: 500,
			color: '#999'
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: 13
		}
	},
	inStock: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 12,
		fontSize: 11,
		color: theme.palette.primary.color,
		'&:before': {
			content: '""',
			width: 5,
			height: 5,
			marginRight: 4,
			borderRadius: '50%',
			backgroundColor: '#2ab382'
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 6
		}
	},
	labels: {
		position: 'absolute',
		top: 12,
		right: 12,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		gap: 6,
		[theme.breakpoints.down('sm')]: {
			top: 10,
			right: 5,
		},
		'&.bfSoldOut': {
			background: 'rgba(0, 0, 0, 0.4)',
			top: 0,
			left: 0,
			right: 0,
			textAlign: 'center',
			alignItems: 'center',
			justifyContent: 'center',
			bottom: 0,
			//aspectRatio: '550/510',
			zIndex: 90,
			'& span': {
				width: '100%',
				height: '50px',
				fontSize: '16px',
				background: '#000',
				color: '#fff',
				[theme.breakpoints.down('sm')]: {
					fontSize: '14px',
					height: '40px',
				},

			}
		},
	},
	discountBubble: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4px 10px',
		fontSize: 12,
		fontWeight: 500,
		background: '#fff',
		color: '#000',
		pointerEvents: 'none',
		'&.light': {
			background: 'transparent',
			color: '#fff',
			border: '1px solid #fff'
		},
		'&.anniversary': {
			background: '#06172a',
			color: '#f9d59f'
		},
		'&.black-week': {
			background: 'black',
			color: 'white'
		},

		[theme.breakpoints.down('sm')]: {
			padding: '2px 8px',
			fontSize: 9
		}
	},
	marketingLabel: {
		position: 'absolute',
		top: 12,
		left: 12,
		zIndex: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4px 10px',
		fontSize: 12,
		fontWeight: 500,
		color: theme.palette.primary.color,
		backgroundColor: theme.palette.primary.background,
		pointerEvents: 'none',
		'&.light': {
			background: 'transparent',
			color: '#fff',
			border: '1px solid #fff',
			fontWeight: 600,
		},
		[theme.breakpoints.down('sm')]: {
			top: 10,
			left: 5,
			padding: '2px 8px',
			fontSize: 9
		}
	},
	fastDelivery: {
		display: 'none',
		position: 'absolute',
		top: 12,
		left: 12,
		padding: '4px 10px',
		fontSize: 12,
		textTransform: 'uppercase',
		'& svg': {
			marginTop: -1,
			marginRight: 4,
			strokeWidth: 0.75,
			color: theme.palette.primary.background,
		},
		[theme.breakpoints.down('sm')]: {
			top: 10,
			left: 5,
			padding: '2px 8px',
			fontSize: 9,
			'& svg': {
				width: 9
			}
		}
	},
	purchaseButtonMinimal: {
		position: 'absolute',
		right: 8,
		bottom: 8,
		zIndex: 10,
		marginTop: 20,
		padding: 10,
		minWidth: 0,
        fontSize: 11,
        color: '#000',
        fontWeight: 600,
		textTransform: 'none',
		backgroundColor: 'transparent',
		borderRadius: '50%',
		'& svg': {
			marginTop: -1,
			color: '#000',
			transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fill: 'transparent',
		},
		'&:hover': {
			backgroundColor: 'transparent',
			mixBlendMode: 'multiply',
			'& svg': {
				fill: '#000',
				'& circle': {
					fill: '#fff',
				}
			}
		},
		'&.light': {
			backgroundColor: 'transparent',
			'& svg': {
				marginTop: -1,
				fill: 'transparent',
				color: '#fff',
				strokeWidth: 10,
				filter: 'drop-shadow(0px 0px 2px #000)',
			},
			'&:hover': {
				backgroundColor: 'transparent',
				mixBlendMode: 'screen',
				'& svg': {
					fill: '#fff',
					'& circle': {
						fill: '#000',
					}
				}
			},
		},
		[theme.breakpoints.down('sm')]: {
			padding: 8
		},
	},
	purchaseTouchRipple: {},
	likeButton: {
		display: 'flex',
		position: 'absolute',
		right: 42,
		bottom: 8,
		zIndex: 20,
		marginTop: 20,
		padding: 10,
		minWidth: 0,
        fontSize: 11,
        color: '#000',
        fontWeight: 500,
		textTransform: 'none',
		backgroundColor: 'transparent',
		'& svg': {
			color: '#000',
			fill: 'transparent',
			strokeWidth: 0.25,
			transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		},
		'&.active svg': {
			color: '#000',
			fill: '#000'
		},
		'&:hover': {
			backgroundColor: 'transparent',
			'& svg': {
				fill: '#000',
			},
		},
		'&.light': {
			right: 42,
			backgroundColor: 'transparent',
			'& svg': {
				color: '#fff',
				fill: 'transparent',
				strokeWidth: 0.75,
				filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.9))',
			},
			'&.active svg': {
				color: '#fff',
				fill: '#fff'
			},
			'&:hover': {
				backgroundColor: 'transparent',
				'& svg': {
					fill: '#fff',
				},
			},
		},
		[theme.breakpoints.down('sm')]: {
			padding: 8,
			right: 45,
		}
	},
	likeLoader: {
		position: 'absolute',
		top: 2,
		left: 2,
	},
	subscribeButtonMinimal: {
		position: 'absolute',
		right: 8,
		bottom: 8,
		zIndex: 10,
		marginTop: 20,
		padding: 10,
		minWidth: 0,
		fontSize: 11,
		color: '#000',
		fontWeight: 500,
		textTransform: 'none',
		backgroundColor: 'transparent',
		'& svg': {
			marginTop: -1,
			color: '#000',
			fill: "transparent",
			transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			strokeWidth: 0.25,
		},
		'&:hover': {
			mixBlendMode: 'multiply',
			backgroundColor: 'transparent',
			'& svg': {
				fill: '#000',
				strokeWidth: '1',
				'& path:nth-of-type(2)': {
					color: '#fff',
				}
			},
		},
		'&.light': {
			backgroundColor: 'transparent',
			'& svg': {
				marginTop: -1,
				color: '#fff',
				fill: "transparent",
				strokeWidth: '0.8',
				filter: 'drop-shadow(0px 0px 2px #000)',
			},
			'&:hover': {
				mixBlendMode: 'screen',
				backgroundColor: 'transparent',
				'& svg': {
					fill: '#fff',
					strokeWidth: '1',
					'& path:nth-of-type(2)': {
						color: '#000',
					}
				},
			},
		},
		[theme.breakpoints.down('sm')]: {
			padding: 8
		},
	},
	purchaseButton: {
		marginTop: 15,
		padding: '8px 24px',
        backgroundColor: 'transparent',
        fontSize: 10,
        color: theme.palette.primary.color,
        fontWeight: 600,
		borderRadius: 0,
		letterSpacing: 0.75,
		textDecoration: 'underline',
		'&:hover': {
			backgroundColor: theme.palette.primary.background,
		},
		'.xmas &': {
			backgroundColor: theme?.palette?.primary.main,
			'&:hover': {
				backgroundColor: theme?.palette?.primary.hover,
			},
		},
	},
	debugTool: {
		position: 'absolute',
	},
	tooltipButton: {
		display: "inline-flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "1.5em",
		left: '3px',
		position: 'absolute',
		zIndex: 999,
		'& + input[type="checkbox"]': {
			width: 0,
			height: 0,
			opacity: 0,
			position: 'absolute',
			'& + pre': {
				position: "absolute",
				fontSize: "0.7em",
				background: "rgba(100, 170, 250, 0.9)",
				width: '360px',
				padding: "1.5em 1em",
				left: '1em',
				zIndex: 99,
				display: 'none',
				wordBreak: 'break-word',
				whiteSpace: 'pre-wrap',
				marginTop: 0,
			},
			'&:checked': {
				'& + pre': {
					display: 'block',
				}
			}
		},
	}
}), {name: 'ProductItem'});

export default function ProductItem({
		id,
		productNumber,
		name,
		image,
		link,
		prices,
		manufacturer,
		variants = false,
		available = true,
		stock = 0,
		deliveryTimeString = {},
		list,
		categoryBreadcrumb,
		sizes = {lg: 3, md: 3, sm: 6, xl: 3, xs: 6},
		position,
		labels = [],
		addToCart = false,
		like = true,
		customClasses = {},
		customAttributes = {
			item: {}
		},
		lazyHydrate = true,
		elevateTicket = false,
		isMobile = false,
		isOutlet = false,
		clickCallback,
		properties = [],
		streamIds = [],
	}) {
	const classes = useStyles({classes: customClasses});
	const {t} = useTranslation();
	const {actions} = useContext(TrackingContext) || {};
	const session = useContext(SessionContext) || {};
	const internalTool = Cookies.get('InternalTools');
	const [loading, setLoading] = useState(false);
	const hasLightButtons = properties?.includes('12dba8fc3431593d890d5802b96bb751') ? 'light' : '';
	const shownInCart = elevateTicket ? 'in-cart' : '';
	const marketingLabels = {
		191: 'outlet'
	}
	let bfSoldOut = '';

	if ( categoryBreadcrumb &&
		(categoryBreadcrumb.includes('Super Deals 70%') || categoryBreadcrumb.includes('Black Week 20-70%')) &&
		deliveryTimeString &&
		deliveryTimeString.id &&
		deliveryTimeString.id === '019257c5169f7d40983b28a28c4e72a3') {
		bfSoldOut = 'bfSoldOut'
	}

	function onClickTracking() {
		actions.productClick({
			id: productNumber,
			name: name,
			price: prices.price,
			discount: Math.abs(prices?.discount?.amount),
			brand: manufacturer,
			category: categoryBreadcrumb,
			list,
			variant: variants,
			quantity: 1
		});

		if(typeof clickCallback !== 'undefined' ) {
			clickCallback(productNumber);
		}
	}

	function isStockAvailable() {
		return stock > 0;
	}
	function isOutdoorInspiration() {
		return (!!(categoryBreadcrumb?.includes('Inspiration') || categoryBreadcrumb?.includes('Outlet')) && categoryBreadcrumb?.includes('Utemöbler'))
	}

	async function purchaseTracking() {
		actions.addToCart({
			id: productNumber,
			name: name,
			price: prices.price,
			discount: Math.abs(prices?.discount?.amount),
			brand: manufacturer,
			category: categoryBreadcrumb,
			list,
			variant: variants,
			quantity: 1
		});

		if(elevateTicket) {
			await elevateAddToCartNotification(
				elevateTicket,
				session.config.elevateMarket,
				session.config.language.languageCode,
				isMobile ? 'mobile' : 'desktop'
			)
		}
	}

	async function handlePurchase(e) {
		e.preventDefault();
	    e.nativeEvent.preventDefault();

		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		setLoading(true);
		await session.actions.addToCart({product: id, quantity: 1});
		setLoading(false);

		await purchaseTracking();
	}
	function isStockExternal() {
		let externalManufacturers = ['Eichholtz', 'Visual Comfort', 'Authentic Models'];

		return externalManufacturers.indexOf(manufacturer) >= 0;
	}

	function renderPromotionWithStreamIds() {
		return <ProductLabels
			streamIds={streamIds}
			available={available}
			prices={prices}
		></ProductLabels>
	}

	function renderItem() {
		return (
			<>
			<Link href={link}>
				<a className={classes.link} onClick={onClickTracking} {...customAttributes.item}>
					<div className={classes.imageWrapper}>
						{image}

						{available && addToCart === 'minimal' ?
							<Button className={[classes.purchaseButtonMinimal, 'add-to-cart', hasLightButtons].join(' ')} TouchRippleProps={{classes: {rippleVisible: classes.purchaseTouchRipple}}} onClick={(event) => {!loading && handlePurchase(event); return false;}}>
								<ShoppingBagFill width={18} height={18} alt={t('buy-short')} />
							</Button>
							:
							<Button className={[classes.subscribeButtonMinimal, hasLightButtons].join(' ')}>
								<Envelope width={18} height={18} alt={t('sold-out-subscribe')} />
							</Button>
						}

						<LikeButton
							show={like}
							productId={id}
                            hasLightButtons={hasLightButtons}
                        ></LikeButton>

						{labels.map(({id, label}) => <span key={id} className={[classes.marketingLabel, hasLightButtons, marketingLabels[id]].join(' ')}>{label}</span>)}

						{variants ? <span className={[classes.moreVariantLabels, hasLightButtons, shownInCart].join(' ') }><FilterNone fontSize={'inherit'} style={{marginRight: 4}}/>{t('additional-variants')}</span> : ''}
					</div>

					<div className={[classes.labels, bfSoldOut].join(' ')}>

						{!available && deliveryTimeString?.translated?.customFields?.delivery_time_texts_main_PLP ?
							<span className={[classes.discountBubble, hasLightButtons].join(' ')}>
								{deliveryTimeString?.translated?.customFields?.delivery_time_texts_main_PLP}
							</span>
							:
							''
						}

						{available && prices.discount ?
							isOutdoorInspiration() ?
								<span
									className={[ classes.discountBubble, 'discountBubble', 'anniversary' ].join(' ')}>{t('last-sale-outdoor')} {Math.floor(prices.discount.percentage)}%</span>
								:
								<span
									className={[ classes.discountBubble, 'discountBubble' ].join(' ')}>{Math.floor(prices.discount.percentage)}%</span>
							: renderPromotionWithStreamIds()
						}
					</div>

					<div className={classes.info}>
						{manufacturer ? <span className={classes.manufacturer}>{manufacturer}</span> : ''}

						<h4 className={classes.name} dangerouslySetInnerHTML={{__html: name}} title={name}/>
						<div className={classes.prices}>
							{prices.isOnSale ?
								<>
									<span className={[classes.price, 'special'].join(' ')}>{formatPrice(prices.price)}</span>
									<span className={[classes.price, 'old'].join(' ')}>{formatPrice(prices.listPrice)}</span>
								</> : <span className={classes.price}>{formatPrice(prices.price)}</span>
							}
						</div>

						{isStockAvailable() ? <span className={classes.inStock}>{isStockExternal() && !isOutlet ? t('in-stock-external') : t('in-stock')}</span> : ''}

						{available && addToCart && addToCart !== 'minimal' ? (
							<Button className={[classes.purchaseButton, 'add-to-cart', theme.mode].join(' ')} onClick={(event) => {!loading && handlePurchase(event); return false;}} disabled={loading}>
								{t('buy-short')}
							</Button>
						) : ''}
					</div>
				</a>
			</Link>

		{internalTool ? (
			<div className={classes.debugTool}>
				<label htmlFor={"status-input-" + id} className={classes.tooltipButton}>ⓘ</label>
				<input type="checkbox" id={"status-input-" + id} />
				<pre>{JSON.stringify({id,
					productNumber,
					name,
					link,
					prices,
					manufacturer,
					variants,
					available,
					stock,
					list,
					categoryBreadcrumb,
					sizes,
					position,
					labels,
					like,
					customClasses,
					customAttributes,
					lazyHydrate,
					elevateTicket,
					isMobile,
					isOutlet,
					clickCallback}, null, 2)}</pre>
			</div>
		) : (
			''
		)}
		</>
	);
	}

	return (
		<Grid
			item={true}
			{...sizes}
			classes={{
				root: classes.item
			}}
		>
			{lazyHydrate ?
				<LazyHydrate id={"ProductItem" + productNumber + position} on="visible">
					{renderItem()}
				</LazyHydrate> : renderItem()
			}
		</Grid>
	);
}
