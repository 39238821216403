import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    brand: {
        fontFamily: 'Chronicle, "Open Sans", sans-serif',
        textTransform: 'uppercase',
        fontSize: '0.9em',
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: 1,
        marginBottom: 2,
        fontSmoothing: 'auto',
    },
}), { name: 'BrandName' });

export default function BrandName({name}) {
    const classes = useStyles();
    return <>
        <div className={classes.brand}>
            {name}
        </div>
    </>;
}
