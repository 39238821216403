import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'tools/i18n';
import { useRouter } from 'next/router';
import useUserStore from '../../../services/stores/UserStore';
import CallToActionButton from '../../products/partials/CallToActionButton';

const styles = {
    loginWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    description: {
        marginBottom: '1rem',
    },
};

const useStyles = makeStyles(() => styles, { name: 'LoginSignup' });


export default function LoginSignupButtons() {
    const router = useRouter();
    const classes = useStyles();
    const { t } = useTranslation([ 'account' ]);
    const myNewportService = useUserStore((state) => state.getService());
    const [ loginLink, setLoginLink ] = useState(myNewportService.createLoginRedirectLink(`${router.basePath}`));
    const [ signupLink, setSignupLink ] = useState(myNewportService.createSignupRedirectLink(`${router.basePath}`));

    useEffect(() => {
        setLoginLink(myNewportService.createLoginRedirectLink(location.href));
        setSignupLink(myNewportService.createSignupRedirectLink(location.href));
    }, [ router.asPath, myNewportService ]);

    return <>
        <section className={classes.loginWrapper}>
            <div className={classes.description}>
                {t('account-login-drawer-description')}
            </div>
            <CallToActionButton colorScheme={'bg-standard'} href={loginLink}>
                {t('account-login')}
            </CallToActionButton>
            <CallToActionButton colorScheme={'bg-light'} href={signupLink}>
                {t('account-signup')}
            </CallToActionButton>
        </section>
    </>;
}
