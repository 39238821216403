import React, {useEffect, useState} from 'react';
import {useTranslation} from '../../tools/i18n';
import {makeStyles} from '@material-ui/styles';
import theme from "../theme";
import Link from "next/link";
import { AppBar, Button } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import {useRouter} from "next/router";
import {ChevronLeft} from "@material-ui/icons";

// Elements
import Topline from "./topline";
import Logo from "./logo";
import Search from "./search/main";
import TopLinks from "./topLinks";
import CheckoutUsps from "./checkoutUsps";
import LazyHydrate from "../../tools/lazyHydrate";
import MobileMenu from "../navigation/mobile/main";
import DesktopMenu from "../navigation/desktop/main";
import Favorites from "./favorites";
import Wishlist from "./wishlist";
import Account from "./account";
import Cart from "./cart";

const useStyles = makeStyles(() => {
    return {
        wrapper: {
            zIndex: 999,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: theme.palette.header.background,
            color: 'inherit',
            boxShadow: 'none'
        },
        inner: {
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: 'repeat(8, minmax(auto, 37px)) repeat(2, minmax(auto, 100%)) repeat(8, minmax(auto, 37px))',
            gridAutoFlow: 'dense',
            alignItems: 'center',
            maxWidth: theme.maxWidth,
            width: '100%',
            padding: '15px 15px',
            '& svg': {
                color: theme.palette.header.icons.color,
            },
            '& .increment svg': {
                color: '#222',
            },
            '& .decrement svg': {
                color: '#222',
            },

            [theme.breakpoints.up('md')]: {
                '&:not(.compact)': {
                    padding: '15px 20px 0'
                }
            },
            [theme.breakpoints.up('lg')]: {
                padding: 15,
                '&:not(.compact)': {
                    padding: '15px 0 0',
                    gridTemplateRows: '1fr'
                }
            },
            [theme.breakpoints.down('sm')]: {
                '&:not(.compact)': {
                    padding: '15px 10px'
                }
            },
            [theme.breakpoints.up('md')]: {
                gridAutoFlow: 'unset',
            },
        },
        innerItem1: {
            gridColumn: '1 / 2',
            display: 'flex',
            [theme.breakpoints.up('sm')]: {
                gridColumn: '1 / 2',
            },
        },
        innerItem2: {
            gridColumn: '2 / 3',
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                gridColumn: '1 / 9',
            },
        },
        innerItem3: {
            gridColumn: '9 / 11',
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                gridColumn: '9 / 11',
            },
        },
        innerItem4: {
            display: 'flex',
            justifyContent: 'center',
            gridColumn: '3 / 4',
            paddingTop: 6,
            minWidth: 35,
            [theme.breakpoints.up('sm')]: {
                gridColumn: '15 / 16',
                paddingTop: 2,
            },
        },
        innerItem5: {
            gridColumn: '16 / 17',
            display: 'flex',
        },
        innerItem6: {
            gridColumn: '17 / 18',
            display: 'flex',
        },
        innerItem7: {
            gridColumn: '18 / 19',
            display: 'flex',
        },
        backToStoreLink: {
            color: 'inherit',
            fontSize: 12,
            letterSpacing: 0.5,
            textTransform: 'uppercase',
            '& .label': {
                display: 'none'
            },
            '& svg': {
                fill: theme.palette.header.icons.color,
            },
            [theme.breakpoints.up('md')]: {
                '& .label': {
                    display: 'block',
                    fontSize: 12,
                    textDecoration: 'none',
                },
                '& svg': {
                    display: 'none'
                }
            }
        },
        checkoutTitle: {
            marginLeft: 10,
            textTransform: 'uppercase',
            fontSize: 14,
            letterSpacing: 1
        },
    }
}, {name: 'header'});

export default function Header({menu, footer}) {
    const classes = useStyles();
    const router = useRouter();
    const {t} = useTranslation(['general']);
    const isMobile = theme.breakpoints.down('md');
    const [isCompact, setCompact] = useState(false);
    const [show, setShow] = useState(true);
    const [showSearch, setShowSearch] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [scrollDirection, setScrollDirection] = useState(null);
    const [initial, setInitial] = useState(false);
    const [searchFocus, setSearchFocus] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setInitial(true);
        }, 1000);

        document.addEventListener("add-to-cart", (e) => {
            setShow(true);
        });
    }, []);

    useEffect(() => {
        if(router.asPath === '/checkout' || router.asPath.indexOf('/checkout/order-payment') >= 0) {
            setCompact(true);
        } else {
            setCompact(false);
        }

        setShowSearch(false);
    }, [router.asPath]);

    useEffect(() => {
        window.addEventListener("scroll", updateScrollDirection); // add event listener

        return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
        };
    }, [lastScrollY, scrollDirection]);

    useEffect(() => {
        if(scrollDirection === 'down') {
            setShow(false);
        } else {
            setShow(true);
        }

        document.dispatchEvent(new CustomEvent("header-scroll"));
    }, [scrollDirection]);

    function updateScrollDirection () {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        const threshold = isMobile ? 160 : 190;
        let scrollPos = false;

        if(direction !== scrollDirection) {
            if (direction === "down" && scrollY > (lastScrollY + threshold)) {
                setScrollDirection(direction);
                scrollPos = scrollY;
            } else if (direction === "up" && scrollY < (lastScrollY - (threshold * 3))) {
                setScrollDirection(direction);
                scrollPos = scrollY;
            }
        } else {
            scrollPos = scrollY;
        }

        if (scrollY < threshold) {
            setShow(true);
            setScrollDirection("up");
        }

        if(scrollPos) {
            setLastScrollY(scrollPos);
        }
    }

    return (
        <Slide appear={true} direction="down" in={show} timeout={{ enter: initial ? 220 : 0, exit: 195 }}>
            <AppBar position={!isCompact ? 'sticky' : 'static'} className={classes.wrapper}>
                {!isCompact && (
                    <>
                        <TopLinks />
                    </>
                )}

                <div className={[classes.inner, isCompact ? 'compact' : ''].join(' ')}>
                    {isCompact && (
                        <Link href={'/'} className={classes.innerItem1}>
                            <a className={classes.backToStoreLink}>
                                <Button>
                                    <ChevronLeft />
                                    <span className={'label'}>{t('back-to-store')}</span>
                                </Button>
                            </a>
                        </Link>
                    )}

                    {!isCompact && (
                        <>
                            <div className={classes.innerItem1}>
                                <MobileMenu menu={menu} footer={footer} />
                            </div>

                            <div className={classes.innerItem2}>
                                <Search
                                    show={showSearch}
                                    emitShow={e => setShowSearch(e)}
                                    searchFocus={searchFocus}
                                    toggle={e => setShowSearch(!showSearch)}
                                />
                            </div>
                        </>
                    )}

                    <div className={classes.innerItem3}>
                        <Logo />
                    </div>

                    {!isCompact && (
                        <>
                            <div className={classes.innerItem4}>
                                <Account />
                            </div>

                            <div className={classes.innerItem5}>
                                <Favorites />
                            </div>

                            <div className={classes.innerItem6}>
                                <Wishlist />
                            </div>

                            <div className={classes.innerItem7}>
                                <Cart />
                            </div>
                        </>
                    )}
                </div>

                {!isCompact && (
                    <LazyHydrate id="desktop-menu" on="touch">
                        <DesktopMenu menu={menu} />
                    </LazyHydrate>
                )}

                {!isCompact && (
                    <>
                        <Topline />
                    </>
                )}

                {isCompact && <CheckoutUsps />}
            </AppBar>
        </Slide>
    );
}
