import { makeStyles } from '@material-ui/styles';
import { MenuItem, Select, TextField } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from '../../../tools/i18n';
import useUserStore from '../../../services/stores/UserStore';
import CallToActionButton from '../partials/CallToActionButton';
import theme from '../../theme';

const useStyles = makeStyles(() => ({
    createWishlistActions: {
        display: 'grid',
        gridTemplateColumns: '120px 220px',
        gap: '8px',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '100px 1fr',
            width: '100%',
            padding: '0rem 2rem',
        },
    },
    createButton: {
        gridColumn: '1 / -1',
    }
}), { name: 'CreateWishlist' });

export default function CreateWishlist(
    {
        show = true,
        type = 'standard'
    },
) {
    const [ wishlistType, setWishlistType ] = useState(type);
    const [ wishlistName, setWishlistName ] = useState();
    const classes = useStyles();
    const { t } = useTranslation();
    const user = useUserStore();

    if (!show) {
        return null;
    }

    async function handleCreateWishlist() {
        const response = await user.wishlistMethods.createNewWishlist({
            name: wishlistName,
            type: wishlistType,
            text: null,
            location: null,
            date: null,
        });
    }

    return <>
        <div className={classes.createWishlistActions}>
            <Select
                labelId="wishlist-type-select"
                id="wishlist-type-select"
                value={wishlistType}
                label="Tema"
                onChange={(value) => {
                    setWishlistType(value.target.value);
                }}
            >
                <MenuItem value={'christmas'}>Christmas</MenuItem>
                <MenuItem value={'standard'}>Standard</MenuItem>
            </Select>
            <TextField
                className={classes.createWishlistInputWrapper}
                variant={'standard'}
                size={'small'}
                label={t('new-wishlist-name')}
                InputProps={{ className: classes.createNameInput }}
                onChange={event => setWishlistName(event.target.value)}
            />
            <CallToActionButton
                className={classes.createButton}
                colorScheme={'bg-light'}
                size={'small'}
                onClick={handleCreateWishlist}
            >
                {t('new-wishlist-button')}
            </CallToActionButton>
        </div>
    </>;
}
